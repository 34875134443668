import React from 'react'
import { AppResourcesEmailBanner } from './AppResourcesEmailBanner'
import SupplierManagementBannerData from '../Data/SupplierManagementBannerData.json'
import styled from 'styled-components'

const AppSupplierManagementBannerWapper = styled.div`
  .SupplierManagementSection {
    display: flex;
    justify-content: space-between;
    padding: 130px 50px 50px 50px;
    @media (max-width: 1090px) {
      flex-wrap: wrap;
      padding: 80px 15px 10px 15px;
    }
  }
  .SupplierManagementImageSection {
    @media (max-width: 1090px) {
      margin: auto;
      padding-top: 30px;
    }
  }
  .SupplierManagementHeading {
    max-width: 850px;
    font-size: 2.5rem;
    line-height: 1.30435em;
    padding: 0px 30px 0px 0px;
    @media (max-width: 1090px) {
      max-width: unset;
      max-width: unset;
    }
  }
  .SupplierManagementPara {
    max-width: 850px;
    padding: 15px 30px 15px 0px;
    @media (max-width: 1090px) {
      max-width: unset;
      max-width: unset;
    }
  }
  .SupplierManagementDatePara {
    max-width: 850px;
    color: #34a1d5;
    font-size: 19px;
    padding: 15px 30px 0px 0px;
    @media (max-width: 1090px) {
      max-width: unset;
    }
  }
  .SupplierManagementBannerImage {
    width: 100%;
    height: auto;
    box-shadow: rgb(160 161 163 / 88%) 0px 3px 7px 0px;
    border-radius: 18px;
     @media (max-width: 1390px) {
      margin-top: 2rem;
    }
     @media (max-width: 1090px) {
      margin-top: unset;
    }
  }
  .DisplayNone {
    display: none;
  }
`

export const AppSupplierManagementBanner = () => {
  return (
    <AppSupplierManagementBannerWapper>
      {SupplierManagementBannerData.map((data, index) => {
        return (
          <div key={index}>
            <AppResourcesEmailBanner
              Heading={data.Title}
              Para={data.Leadpara}
              DatePara={data.DatePara}
              ImagePath={data.ImagePath}
              AltText={data.AltText}
              LeadParaStyle='DisplayNone'
              BannerHeading='SupplierManagementHeading'
              BannerPara='SupplierManagementPara'
              DateBannerPara='SupplierManagementDatePara'
              BannerImageSection='SupplierManagementImageSection'
              BannerImage='SupplierManagementBannerImage'
              BannerSection='SupplierManagementSection'
            />
          </div>
        )
      })}
    </AppSupplierManagementBannerWapper>
  )
}

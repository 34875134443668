import React from 'react'
import styled from 'styled-components'
import { ReUsePtag } from './ReUsePtag'
import { ReUseHTwotag } from './ReUseHTwoTag'
import SupplierManagementRestData from '../Data/SupplierManagementRestData'
// import { ReUsePtagwithAnchorTag } from './ReUsePtagwithAnchorTag'

const AppSupplierManagementRestWapper = styled.div`
  max-width: 1920px;
  margin: auto;
  .SupplierManagementContentSectionEmail {
    padding: 5px;
    @media (max-width: 1090px) {
      padding: 15px;
    }
  }
  .SupplierManagementHeadingEmail {
    max-width: 1150px;
    padding: 0px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .SupplierManagementLeadParaStyleOne {
    max-width: 1150px;
    padding: 0px 15px 15px 45px;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .SupplierManagementLeadParaStyle {
    max-width: 1150px;
    padding: 15px 15px 15px 45px;

    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .SupplierManagementLeadParaSubPointStyle {
    color: #047cc2;
    max-width: 1150px;
    padding: 15px 15px 15px 45px;
    font-size: 22px;
    font-weight: bold;
    @media (max-width: 1090px) {
      padding: unset;
      max-width: unset;
    }
  }
  .achorPara {
    color: #34a1d5;
    font-size: 20px;
    font-weight: 700;
    padding-right: 8px;
    padding-left: 0px;
    text-decoration: none;
  }
  .achorPara:hover {
    color: #047cc2;
    text-decoration: underline;
  }
  .PointColor {
    font-size: 22px;
    font-weight: bold;
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
  .iconColor {
    color: #34a1d5;
    padding: 15px 15px 15px 0px;
  }
`

export const AppSupplierManagementRest = () => {
  return (
    <AppSupplierManagementRestWapper>
      <div>
        {SupplierManagementRestData.map((data, index) => {
          return (
            <div className='MainRFPProcessNeedsSection' key={index}>
              <div className='SupplierManagementContentSectionEmail'>
                <ReUsePtag para={data.ParaOne} paraStyle='SupplierManagementLeadParaStyleOne' />
                <ReUsePtag para={data.ParaTwo} paraStyle='SupplierManagementLeadParaStyle' />
                <ReUsePtag para={data.ParaThree} paraStyle='SupplierManagementLeadParaStyle' />
                <ReUsePtag para={data.ParaFour} paraStyle='SupplierManagementLeadParaStyle' />
                <ReUsePtag
                  Icon={data.PointPara}
                  para={data.ParaFourPointOne}
                  paraStyle='SupplierManagementLeadParaStyle'
                  IconStyle='iconColor'
                />
                <ReUsePtag
                  Icon={data.PointPara}
                  para={data.ParaFourPointTwo}
                  paraStyle='SupplierManagementLeadParaStyle'
                  IconStyle='iconColor'
                />
                <ReUseHTwotag Heading={data.HeadingOne} HeadingStyle='SupplierManagementHeadingEmail' />
                <ReUsePtag para={data.ParaFive} paraStyle='SupplierManagementLeadParaStyle' />
                <ReUsePtag para={data.ParaSix} paraStyle='SupplierManagementLeadParaStyle' />
                <ReUseHTwotag Heading={data.HeadingTwo} HeadingStyle='SupplierManagementHeadingEmail' />
                <ReUsePtag
                  Icon={data.PointPara}
                  para={data.HeadingTwoPointOne}
                  paraStyle='SupplierManagementLeadParaSubPointStyle'
                  IconStyle='iconColor'
                />
                <ReUsePtag para={data.ParaSeven} paraStyle='SupplierManagementLeadParaStyle' />
                <ReUsePtag
                  Icon={data.PointPara}
                  para={data.HeadingTwoPointTwo}
                  paraStyle='SupplierManagementLeadParaSubPointStyle'
                  IconStyle='iconColor'
                />
                <ReUsePtag para={data.ParaEight} paraStyle='SupplierManagementLeadParaStyle' />
                <ReUsePtag
                  Icon={data.PointPara}
                  para={data.HeadingTwoPointThree}
                  paraStyle='SupplierManagementLeadParaSubPointStyle'
                  IconStyle='iconColor'
                />
                <ReUsePtag para={data.ParaNine} paraStyle='SupplierManagementLeadParaStyle' />
                <ReUsePtag
                  Icon={data.PointPara}
                  para={data.HeadingTwoPointFour}
                  paraStyle='SupplierManagementLeadParaSubPointStyle'
                  IconStyle='iconColor'
                />
                <ReUsePtag para={data.ParaTen} paraStyle='SupplierManagementLeadParaStyle' />
                <ReUsePtag
                  Icon={data.PointPara}
                  para={data.HeadingTwoPointLast}
                  paraStyle='SupplierManagementLeadParaSubPointStyle'
                  IconStyle='iconColor'
                />
                <ReUsePtag para={data.ParaLast} paraStyle='SupplierManagementLeadParaStyle' />
                <ReUsePtag
                  Icon={data.PointPara}
                  para={data.HeadingTwoPointFive}
                  paraStyle='SupplierManagementLeadParaSubPointStyle'
                  IconStyle='iconColor'
                />
                <ReUsePtag para={data.ParaEleven} paraStyle='SupplierManagementLeadParaStyle' />
                <ReUsePtag
                  Icon={data.PointPara}
                  para={data.HeadingTwoPointSix}
                  paraStyle='SupplierManagementLeadParaSubPointStyle'
                  IconStyle='iconColor'
                />
                <ReUsePtag para={data.ParaTwelve} paraStyle='SupplierManagementLeadParaStyle' />
              </div>
            </div>
          )
        })}
      </div>
    </AppSupplierManagementRestWapper>
  )
}

import React from 'react'
import { FaArrowCircleRight } from 'react-icons/fa'

export default [
  {
    id: '1',
    icon: <FaArrowCircleRight />,
    PointPara: '⟶',
    ParaOne: "The complexity in managing an entire supplier network, capable of delivering the goods and services necessary to run your company and produce your product and/or service, is increasing far above that from even a decade ago.",
    ParaTwo: "Diversity and inclusion, risk management, information security, compliance, quality, performance, and numerous other supplier-focused categories are now leading the focus across many Board rooms and conference rooms.  Data-driven analytics and automated business functions are now the expected capabilities from any platform software provider.",
    ParaThree: "This is where Raindrop’s Supplier Relationship Management (SRM) module comes in, which provides insights into how your suppliers are performing on each of the above key topics.  From onboarding, to continuous improvement, Raindrop makes it easy to understand the status of your suppliers and extract the most value from each relationship.",
    ParaFour: "This article covers",
    ParaFourPointOne: "Raindrop’s SRM module",
    ParaFourPointTwo: "Raindrop SRM benefits and key features",
    HeadingOne: "Raindrop SRM",
    ParaFive: "Raindrop provides insights into how your suppliers are performing, as well as the industries they reside in. From onboarding to continuous improvement, Raindrop makes it easy to understand the financial, contractual, performance status, and more, of your suppliers and extract the most value from each relationship.  Additionally, suppliers can self-register and maintain all necessary documentations and certifications, with examples such as certificates of insurance, code of conduct acceptance, as well as industry-specific compliance issues such as human trafficking, conflict minerals, etc.",
    ParaSix: 'Raindrop is specifically designed to provide 360-degree supplier insights & transparency. From "who is buying, and from which supplier?", to "how much are we spending?", to "when does their contract expire?". With Raindrop, lets redefine the way we interact with our suppliers. Define the action plans, assign the stakeholders, and measure results for every supplier engagement. Raindrop enables the procurement team to create and manage a comprehensive supplier relationship management plan from planning through an operation. ',
    HeadingTwo: "Raindrop SRM benefits and key features",
    HeadingTwoPointOne: "Harness Spend Leakage",
    ParaSeven: "By automating a lot of manual tasks and having an up-to-date database with all your suppliers with respect to the spend, commodities purchased, contracts in place, next events etc. helps in providing a complete picture of supplier performance, risks, areas of improvement etc. All this tie to one key aspect to harness spend leakage.",
    HeadingTwoPointTwo: "Risk Management",
    ParaEight: "Raindrop provides a 360-degree view on your suppliers and provides insights into compliance requirements. With Raindrop stay on top with your supplier’s compliance and create a streamlined governance process to identify or mitigate any risks. ",
    HeadingTwoPointThree: "Transparency",
    ParaNine: "Empowerment and collaboration are the keys to successful supplier relations. Raindrop’s supplier portal provides a timely and transparent flow of information between you and your suppliers.",
    HeadingTwoPointFour: "Supplier Self Service",
    ParaTen: "You need accurate and up-to-date supplier information and your suppliers need updates on their purchase orders, invoices and payments.  Empower your suppliers with self-service access and reduce the burden on you and your teams.",
    HeadingTwoPointLast: "Raindrop Supplier Network",
    ParaLast: "If you are considering a new purchase and have not yet identified suppliers, save time by leveraging Raindrop’s network of top-quality companies with pre-negotiated discounts for popularly purchased goods and services.",
    HeadingTwoPointFive: "Collaboration",
    ParaEleven: "Interact and communicate with suppliers easily and efficiently.  Whether you need to send a new sourcing request to your suppliers for a new opportunity or need supplier documents, Raindrop’s supplier collaboration features facilitate effective communication, all in one centralized place.",
    HeadingTwoPointSix: "Easy Onboarding",
    ParaTwelve: "Quickly add new suppliers.  Automatically check credit risk and OFAC sanctions lists. Raindrop is designed to enable suppliers to manage their own profiles, reducing your administrative overhead.  Simply invite suppliers to submit standar documents for your review, and route for approvals.  The process is even faster if they are already connected to other Raindrop customers."
  },
]
